@import "node_modules/bootstrap/scss/functions";
@import "settings/variables";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "tools/mixin";
@import "components/richtext";
@import "components/button";

#tinymce {
    @extend .richtext;
    padding: 15px;
    margin: 0;

    .mceSnippet {
        font-family: Arial, Tahoma, Helvetica, sans-serif;
        opacity: .5;
        font-style: italic;
        text-align: center;
        padding: 5px 0;
        background: none;
        margin: 0 0 $default-margin;
        outline: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    p,
    blockquote,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    form,
    table,
    section,
    aside,
    article,
    dl,
    hr,
    address {
        margin: 0 0 $default-margin;
    }
}
