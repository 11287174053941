// Common
$default-margin: 30px;
$default-block-margin: 60px;
$heading-font: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;
$text-font: "Roboto Condensed", "Roboto Condensed-fallback", sans-serif;
$font-family-base: $text-font;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$text-size: 20px;
$line-height: 30px;
$text-color: #666;
$color-white: #fff;
$color-pink: #ed1164;
$color-pink-dark: darken(#ed1164, 10%);
$color-black: #000;
$color-gray: #adb5bd; // TODO: remove usage $color-gray, replace with gray-500
$color-red: #db001b;
$color-yellow: #ffea50;
$color-blue: #0077b5;
$color-green: #28a745;
$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;
$header-height: 80px;

$primary: $color-pink;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

$theme-colors: (
    "primary":      $primary,
    "secondary":    $secondary,
    "success":      $success,
    "info":         $info,
    "warning":      $warning,
    "danger":       $danger,
    "light":        $light,
    "dark":         $dark,
    "black":        $black,
    "white":        $white,
    "gray-100":     $gray-100,
    "gray-200":     $gray-200,
    "gray-300":     $gray-300,
    "gray-400":     $gray-400,
    "gray-500":     $gray-500,
    "gray-600":     $gray-600,
    "gray-700":     $gray-700,
    "gray-800":     $gray-800,
    "gray-900":     $gray-900
);

$gutters: $spacers !default;
