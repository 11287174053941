button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.btn {
    $self: &;

    @include button-variant($primary, $primary, $color: $color-white, $hover-background: shade-color($primary, $btn-hover-bg-shade-amount));

    --bs-btn-padding-x: 1em;
    --bs-btn-padding-y: .75em;
    --bs-btn-border-width: 2px;
    --bs-btn-border-radius: 0;

    position: relative;
    display: inline-block;
    font-family: $heading-font;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-align: center;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &--icon {
        padding-left: 2.5em;

        &::after {
            position: absolute;
            top: 9px;
            left: 10px;
            display: block;
            width: 20px;
            height: 20px;
            content: "";
        }

        &.btn {
            &-social-share {
                &::after {
                    @include icon(share, $color-white, 20px, 20px);
                }
            }
        }
    }

    &-to-top {
        @include icon(arrow, $color-white, 20px, 20px);
        position: fixed;
        right: 50px;
        bottom: 50px;
        display: block;
        width: 45px;
        height: 45px;
        font-size: 0;
        z-index: 9999;
        opacity: 0;
        transform: rotate(-180deg);
        border-radius: 50%;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: darken($primary, 10%);
        }

        &.active {
            opacity: 1;
        }
    }

    &-whatsapp {
        background-color: $color-whatsapp;
        border-color: $color-whatsapp;
        padding-left: 2em;

        &:hover,
        &:focus {
            background-color: darken($color-whatsapp, 10%);
            border-color: darken($color-whatsapp, 10%);
        }

        &::after {
            @include icon(whatsapp, $color-white, 24px, 24px);
            content: "";
            height: 24px;
            left: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
        }
    }

    &-social-share {
        --bs-btn-active-bg: transparent;
        --bs-btn-active-color: $primary;
        --bs-btn-hover-color: $primary;

        color: $text-color;
        font-size: rem-convert(16px);
        line-height: 22px;
        font-weight: $font-weight-normal;
        padding: .5em 0 .5em 1.7em;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: transparent;

            &::after {
                @include icon(share, $primary, 20px, 20px);
            }
        }

        &::after {
            @include icon(share, $text-color, 20px, 20px);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 32px;
            height: 32px;
            content: "";
        }
    }
}

// Redeclare btn-* classes to set the correct css variables (--bs-btn-*)
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color == "light" {
            @include button-variant(
                $value,
                $value,
                $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
                $hover-border: shade-color($value, $btn-hover-border-shade-amount),
                $active-background: shade-color($value, $btn-active-bg-shade-amount),
                $active-border: shade-color($value, $btn-active-border-shade-amount)
            );
        } @else if $color == "dark" {
            @include button-variant(
                $value,
                $value,
                $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
                $hover-border: tint-color($value, $btn-hover-border-tint-amount),
                $active-background: tint-color($value, $btn-active-bg-tint-amount),
                $active-border: tint-color($value, $btn-active-border-tint-amount)
            );
        } @else {
            @include button-variant($value, $value);
        }
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

.btn-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
}